<template>
  <v-content id="inscription" :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'">
    <router-view />
    <v-snackbar
      v-model="$store.state.app.notification.show"
      :timeout="$store.state.app.notification.timeout"
      :color="$store.state.app.notification.color"
    >
      {{ $store.state.app.notification.message }}
    </v-snackbar>
  </v-content>
</template>

<script>
export default {
  name: "PagesCoreView",

  data: () => ({}),

  computed: {
    src() {
      return this.srcs[this.$route.path];
    }
  }
};
</script>

<style lang="sass">
</style>

